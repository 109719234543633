Vue.component('carModalComponent', {
    data:()=>{
        return {
            status:'view',
            errorMsgs:[],
            detailItem:null,
            showDetail:false,
            sucessCreate:false,
            completeDetail:false,
            salesOrder :{},
            orderComment :'',
            purchaseOrder:'',
            deliveryDate:moment().format('YYYY-MM-DD'),
            TaxRegTypes:['Reg.Company', 'Final Consumer', 'IVA No Resp.', 'Exempt', 'Resp. no Insc.', 'Monotributo', 'International Exempt', '', 'Sujeto no Categorizado'],
            centerCostList:[],
            selectedCenterCost:null,
        };
    },
    watch: {
        '$route':'forceUpdate',
        '$store.state.cartStatus':'updateStatusCar',
    },
    computed:{
        validCenterCost(){
            let validData = false;
            if(this.centerCostList.length > 0){
                if(!this.selectedCenterCost) {
                    validData = true;
                }
            }
            return validData;
        },
        validPurchaseOrder(){
            let validData = false;
            if(!validData && this.$store.getters.isRequiredPurchaseOrder && (this.purchaseOrder == ''  || !this.purchaseOrder))
                validData =  true;
            return validData;
        },
        validateData(){
            return this.validCenterCost || this.validPurchaseOrder;
        },
        show(){
            return this.$store.getters.getCarModal;
        },
        customer(){
            return this.$store.getters.getCustomer;
        },
        checkOutReady(){
            return this.$store.getters.getItemCar.length > 0;
        },
        orderTemplate(){
            return this.$store.getters.getOrderTemplate;
        },
        total(){
            return this.$store.getters.getCarAmount;
        },
        totalIVA(){
            return this.$store.getters.getCarAmountIVA;
        },
        itemsSelected:function (){
            let items = this.$store.getters.getItemCar;
            let newitems = new Map();
            for(let item of items){
                let storeItem = this.$store.getters.getItemByCode(item.id);
                if(!newitems.has(item.id))
                    newitems.set(item.id,{id:item.id,item:storeItem,cant:item.cant});
                else
                    newitems.get(item.id).cant = newitems.get(item.id).cant + 1;
            }
            if(!this.showDetail)
                return Array.from(newitems.values());
            else
                return Array.from(newitems.values()).filter(i=> i.id == this.detailItem.Code);
        },
    },
    updated:function (){
        this.fetchData();
    },
    mounted:function (){
        this.purchaseOrder = this.$store.getters.getSOCustPurchaseOrder;
        let self = this;
        axios.get(`/ecommerce/api/getCenterCost`).then(function (response) {
            self.centerCostList = response.data.centerCost;
            if(self.centerCostList.length == 1)
                self.selectedCenterCost = self.centerCostList[0].Code;
            else{
                if(self.$store.state.session)
                    self.selectedCenterCost = self.$store.state.session.fields.DefaultCenterCost;
            }
            if(self.selectedCenterCost)
                self.$store.dispatch('updateOrderCenterCost',self.selectedCenterCost);
        
            //console.log('Load',self.centerCostList)
        });
        this.$nextTick(() => {
            this.fetchData();
            $('.stepper').activateStepper({
                autoFormCreation: false,
            });

        });
    },
    methods:{
        getPayTermName(){
            if(this.orderTemplate.Comment){
                return this.orderTemplate.Comment;
            }
            else{
                for(let payTerm of this.$store.getters.getSetting.PayTermRow)
                    if(payTerm.fields.PayTermCode == this.customer.PayTerm)
                        return payTerm.fields.PayTermName;
            }
            return this.customer.PayTerm;
        },
        fetchData() {
            this.itemFlag = !this.itemFlag;
            let itemWithPrices = [];
            for(let item of this.itemsSelected) {
                let storeItem = this.$store.getters.getItemByCode(item.id);
                if (storeItem && !storeItem.isCalculate)
                    itemWithPrices.push(storeItem);
            }
            let self = this;
            if(itemWithPrices.length > 0)
                this.$store.dispatch('updatePrices', itemWithPrices).then(function (res){
                    self.$store.dispatch('updateCarAmount');
                });

        },
        updateStatusCar:function () {
            this.status = this.$store.getters.getCarStatus;
        },
        activeCenterCost(){
            //console.log( "Selected CenterCost",this.selectedCenterCost );
            this.$store.dispatch('updateOrderCenterCost',this.selectedCenterCost);
        },
        async payAndClose() {
            // this.$store.commit('updateCarStatus','loading');
            this.errorMsgs = [];
            this.status = "loading";
            let payload = {
                DeliveryDate: this.deliveryDate,
                Comment: this.orderComment,
                SOGroup:"WEB",
                SalesOrder : this.salesOrder,
                purchaseOrder:this.purchaseOrder
            };
            let creatResp = await this.$store.dispatch('createSaleOrder',payload);
            this.salesOrder = creatResp.so.fields;
            if(creatResp.success) {
                this.status = "result";
            }
            else
                this.status = "failure";
            if(Array.isArray(creatResp.message))
                this.errorMsgs = creatResp.message;
            else
                this.errorMsgs = [creatResp.message];
            //console.log( 'CREATE ORDER RESPONSE',this.errorMsgs,creatResp.message );
        },
        goToOrderList:function (){
            this.$router.push('latestshopping/all');
        },
        itemQtyChange(itemCode){
            //console.log( 'manual change',itemCode );
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'update',itemQty:itemCode.cant});
            //this.$store.commit('updateCarAmount');
        },
        itemQtyIncrement:function (itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'up'});
        },
        itemQtyDecrement:function (itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'down'});
        },
        deleteItemFromCar:function (itemCode){
            this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'delete'});
        },
        open() {
            let self = this;
            $('#carModal').modal({
                complete:function (){self.$store.commit('updateCarStatus','view');}
            });
            if(this.show)
                $('#carModal').modal('open');
        },
        close:function () {
            this.$store.commit('updateCarStatus','view');
            this.$router.go(-1);
        },
        showItemPrice: function (item){
            return this.$store.getters.showItemPrice(item);
        },
        rowPriceTotal:function (item){
            if(item.cant)
                return parseFloat(parseFloat(item.cant) * parseFloat(this.$store.getters.showItemPrice(item.item)) * 100 / 100).toFixed(2);
            else
                return 0;
        },
        showPriceLabel: function () {
            if(this.$store.getters.getPriceToShow)
                return '(IVA INC)';
            else{
                return '(IVA EXC)';
            }
        },
        goToStep: function (newState){
            //console.log('State Control',newState);
            switch(newState){
                case 'detail':
                    if(!this.sucessCreate) {
                        break;
                    }
                    this.status = newState;
                    break;
                case 'orderResult':
                    if(!this.sucessCreate) {
                        this.status = 'orderResult';
                        break;
                    }
                    if(!this.completeDetail) {
                        this.status = 'detail';
                        break;
                    }
                    this.status = newState;
            }
            this.$store.commit('updateCarStatus',this.status);
        },
        viewDetailItem:function (item){
            if(this.detailItem == null || this.detailItem.Code != item.Code) {
                this.detailItem = item;
                this.showDetail = true;
            }
            else{
                if(this.detailItem.Code == item.Code) {
                    this.detailItem = null;
                    this.showDetail = false;
                }
            }
            //console.log('View Detail');
            //console.log(this.detailItem);
        },
        messageTR:function (msm){
            let trMsM = this.$store.getters.trWithParrams(msm);
            return trMsM;
        },
        resetSteps(){
            $('.stepper').openStep(1);
        }

    },
    template: `
              <div id="carModal" class="cart-container" >
                  <ul  ref="OrderStepper" class="stepper linear horizontal">
                      <li v-bind:class="{'step': true, 'active': (status=='view')}" >
                          <div class="step-title waves-effect">{{tr("Items in Cart")}}</div>
                          <div class="step-content">
                               <div class="order-items">
                                   <template v-if="showDetail">
                                        <carModalDetailComponent :item="detailItem" :backToList="viewDetailItem"></carModalDetailComponent>
                                   </template>
                                   <table class="striped">
                                       <thead>
                                           <tr>
                                               <th>{{tr("Code")}}</th>
                                               <th>Art.</th>
                                               <th>{{tr("Item")}}</th>
                                               <th>{{tr("Unit")}}</th>
                                               <th>{{tr("Price")}}{{showPriceLabel()}}</th>
                                               <th colspan="3" class="center">{{tr("Qty")}}</th>
                                               <th>{{tr("Total")}}</th>
                                               <th></th>
                                           </tr>
                                       </thead>
                                       <tbody v-if="itemsSelected.length>0">
                                           <tr v-for="item in itemsSelected" >
                                             <td>{{item.item.Code}}</td>
                                             <td>{{item.item.AlternativeCode}}</td>
                                             <td @click="viewDetailItem(item.item)">
                                                <span class="blue-text text-darken-2">{{item.item.Name}}</span>
                                             </td>
                                             <td>{{item.item.Unit}}</td>
                                             <td class="center" >{{ showItemPrice(item)}}</td>
                                             <td width="45" class="center" @click="()=>itemQtyIncrement(item)">
                                                <span class="row">
                                                    <i class="fas fa-plus-circle"></i>
                                                </span>
                                            </td>
                                            <td width="45" class="center"> 
                                                <input type="number" min="0"  max='100000' v-model="item.cant" @keyup="itemQtyChange(item)"  class="center-align">
                                            </td>
                                            <td width="45" class="center" @click="()=>itemQtyDecrement(item)">
                                                <span class="row" >
                                                    <i class="fas fa-minus-circle"></i>
                                                </span>
                                             </td>
                                             <td>{{rowPriceTotal(item)}}</td>
                                             <td width="45"  class="center" @click="()=>deleteItemFromCar(item)">
                                                <span class="row"  >
                                                    <i class="far fa-trash-alt"></i>
                                                </span>
                                             </td>   
                                           </tr>
                                       </tbody>
                                   </table>
                               </div>
                               <div class="row">
                                   <div class="col s12 total" v-if="!showDetail">
                                       <strong class="col s10 right-align">Total</strong>
                                       <strong class="col s2 right-align">$ {{total}}</strong>
                                       <strong class="col s10 right-align">Total IVA</strong>
                                       <strong class="col s2 right-align">$ {{totalIVA}}</strong>
                                   </div>
                               </div>
                               <div class="step-actions" v-if="!showDetail">
                                    <button @click="goToStep('detail')" class="btn waves-effect green btn-flat white-text next-step" v-if="checkOutReady">{{tr("Continue")}}</button>
                                    <button @click.prevent="close" class="btn modal-action modal-close waves-effect red btn-flat white-text">{{tr("Cancel")}}</button>
                               </div>
                               <div class="col s12">
                                       <span class="detalle" ><strong>Referencias de Empaque: </strong><br>
                                        EA: Unidad - PK: Pack - BX: Caja - RM: Resma - TB: Tubo - ST: Set - BL: Blister</span>
                               </div>
                          </div>
                      </li>
                      <li v-bind:class="{'step': true , 'active': (status=='detail')}" v-if="$store.getters.getIsLogin">
                          <div class="step-title waves-effect">Detalle</div>
                          <div class="step-content">
                              <div>
                                    <div class="row sep-carrito" v-if="centerCostList.length>0">
                                          <div class="col m4 s12 tit-col">
                                              <p>SELECCIONE UNA OPCIÓN</p>
                                          </div>
                                          <div class="col m5 s12 det-col" >
                                              <select class="browser-default"  name="centercost-value" v-model='selectedCenterCost' v-on:change="activeCenterCost()" style="border-width: 1px;border-style: solid;border-color: rgb(169, 169, 169);">
                                                    <template v-if="centerCostList.length==1">
                                                            <option :value="centerCostList[0].Code" selected >{{centerCostList[0].Name}}</option>
                                                    </template>
                                                    <template v-else>
                                                         <template v-if="$store.state.session.fields.DefaultCenterCost==null">
                                                             <option value="null" selected>{{tr('Select a Customer Center Cost')}}</option>
                                                         </template>
                                                         <template v-for="ccObj in centerCostList">
                                                            <template v-if="$store.state.session.fields.DefaultCenterCost==ccObj.Code">
                                                                <option :value="ccObj.Code" selected>{{ccObj.Name}}</option>
                                                            </template>
                                                            <template v-else>
                                                                <option :value="ccObj.Code" >{{ccObj.Name}}</option>
                                                            </template>
                                                         </template>
                                                       
                                                    </template>
                                              </select>
                                              <template v-if="validCenterCost">
                                                  <label for="centercost-value" data-error="Please enter a value.">Campo Obligatorio</label>
                                              </template>
                                          </div>
                                     </div>  
                                     <div class="row sep-carrito-tit">
                                          <div class="col m4 s12">
                                              <h3>DETALLE DEL PEDIDO</h3>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>CONTACTO DEL SOLICITANTE</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{this.$store.getters.getSession.fields.Name}} {{this.$store.getters.getSession.fields.LastName}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>DEPARTAMENTO</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>COMPRAS</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>FORMA DE PAGO</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{getPayTermName()}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>NÚMERO DE ORDEN DE COMPRA</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <input v-model="purchaseOrder" type="text" value="" placeholder="Orden de Compra" :required="$store.state.requirePurchaseOrder">
                                              <template v-if="validPurchaseOrder">
                                                  <label for="map-name" data-error="Please enter a value.">Campo Obligatorio</label>
                                              </template>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>TOTAL A PAGAR</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p><strong class="det-col-price">$ {{$store.getters.getCarAmountIVA}}</strong></p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>COMENTARIO</p>
                                          </div>
                                          <div class="col m5 s12 det-col input-field">
                                              <textarea v-model="orderComment" ></textarea>
                                          </div>
                                      </div>

                                      <div class="row sep-carrito-tit">
                                          <div class="col m4 s12">
                                              <h3>DATOS DE FACTURACIÓN</h3>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>RAZÓN SOCIAL</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{customer.Name || customer.FantasyName}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>CONDICIÓN DE IVA</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{tr(TaxRegTypes[customer.TaxRegType])}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>CUIT</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{customer.TaxRegNr}}</p>
                                          </div>
                                      </div>

                                      <div class="row sep-carrito-tit">
                                          <div class="col m4 s12">
                                              <h3>DETALLE DE LA ENTREGA</h3>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>DIRECCIÓN DE ENTREGA</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{customer.DelAddress}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>LOCALIDAD</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{customer.DelLocality ||  customer.DelCity }}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>PROVINCIA</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{orderTemplate.Province || customer.DelProvince}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>DÍA DE ENTREGA</p>
                                          </div>
                                          <div class="col m3 s12 det-colfecha">
                                              <input type="date" name="" v-model="deliveryDate">
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>HORARIO DE ENTREGA</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>{{customer.DelFromTime || '09:00'}} a {{customer.DelToTime || '13:00'}}</p>
                                          </div>
                                      </div>
                                      <div class="row sep-carrito">
                                          <div class="col m4 s12 tit-col">
                                              <p>CONTACTO DE ENTREGA</p>
                                          </div>
                                          <div class="col m5 s12 det-col">
                                              <p>
                                                  <template v-if="customer.MainContactName">
                                                    {{customer.MainContactName}}
                                                  </template>
                                                  <template v-else>
                                                     {{this.$store.getters.getSession.fields.Name}} {{this.$store.getters.getSession.fields.LastName}}
                                                  </template>
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              <div class="step-actions">
                                  <button  @click="payAndClose" class="btn waves-effect green btn-flat white-text next-step" :class="{disabled:validateData}">Completar compra</button>
                                  <button  class="waves-effect btn-flat previous-step">Volver al carrito</button>
                              </div>
                          </div>
                      </li>
                      <li v-bind:class="{'step': true, 'active': (status=='orderResult')}" v-if="$store.getters.getIsLogin">
                          <div class="step-title waves-effect">{{tr('Result')}}</div>
                          <div class="step-content">
                              <template v-if="status=='loading'">
                                  <div key="order-loaging">
                                      <h5 class="center-align">{{tr("Your Order is processing")}}.{{tr("Please Wait")}}.</h5>
                                  </div>
                              </template>
                              <template v-if="status=='failure'">
                                  <div class="center">
                                      <h4 class="center-align">{{tr("The Sales Order can't be created")}}.</h4>
                                      <h5 class="center-align">{{tr("Reasons")}}:</h5>
                                      <div class="left-align">
                                         <ol>
                                            <template v-for="msg of errorMsgs">
                                                <h6><li>{{messageTR(msg)}}</li></h6>
                                            </template>
                                         </ol>
                                      </div>
                                      <button  class="btn btn-flat" @click="resetSteps">Volver a Detalle de la Compra</button>
                                  </div>
                              </template>
                              <template  v-if="status=='result'">
                                  <div  class="center">
                                      <h4 class="center-align">{{tr("Congratulation Sales Order was created")}}.</h4>
                                       <template v-if="salesOrder.OrderStatus=='PRE'">
                                                <h5 >{{tr('Your order require preauthorization for this reasons')}}:</h5>
                                                <div key="order-result" class="left-align">
                                                    <ol>
                                                        <template v-for="msg of errorMsgs">
                                                            <h5><li>{{messageTR(msg)}}</li></h5>
                                                        </template>
                                                     </ol>
                                               </div>
                                       </template>
                                       <template v-else>
                                                <template v-for="msg of errorMsgs">
                                                    <h5>
                                                        <li>{{messageTR(msg)}}</li>
                                                    </h5>
                                                </template>
                                       </template>
                                        <button  class="btn btn-flat" @click="goToOrderList">Ir a Tracking de Órdenes</button>
                                  </div>
                              </template>
                          </div>
                      </li>
                  </ul>
              </div>`,
});